import styled from 'styled-components';
import * as defaultTheme from '../variables';
import { fadeInUp } from '../animations';

import bgMobile from '../../images/bg_HeroCs_mobile.jpg';
import bgTab from '../../images/bg_HeroCs_tab.jpg';
import bgDesktop from '../../images/bg_HeroCs_dsktp.jpg';

const HeroLpStyled = styled('div')`
  position: relative;
  margin-top: 60px;
  color: ${p => p.theme.white};
  background-color: ${p => p.theme.black};
  background-repeat: no-repeat;
  animation-name: ${fadeInUp};
  animation-duration: 800ms;
  animation-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
  z-index: 1;

  @media (${p => p.theme.xsMax}) {
    background-image: url(${bgMobile});
    background-size: 190%;
    background-position: center top;
  }

  @media (${p => p.theme.smMin}) and (${p => p.theme.mdMax}) {
    background-image: url(${bgTab});
    background-size: cover;
    background-position: right top;
  }

  @media (${p => p.theme.lgMin}) {
    background-image: url(${bgDesktop});
    background-size: 145%;
    background-position: right top;
  }

  .HeroLp__video {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.25);
    }

    > video {
      min-width: 100%;
      min-height: 100%;
      width: auto;
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .HeroLp__inner {
    position: relative;
    max-width: 480px;
    margin: 0 auto;
    padding: 75px 20px;

    @media (${p => p.theme.smMin}) {
      max-width: ${p => p.theme.maxWidth}px;
      min-height: 474px;
    }

    @media (${p => p.theme.lgMin}) {
      min-height: 544px;
      padding: 75px 0;
    }
  }

  .HeroLp__title {
    max-width: 315px;

    @media (${p => p.theme.smMin}) {
      max-width: 450px;
    }

    @media (${p => p.theme.lgMin}) {
      max-width: 575px;
    }
  }

  .HeroLp__desc {
    @media (${p => p.theme.smMin}) {
      width: 417px;
    }

    @media (${p => p.theme.lgMin}) {
      width: 495px;
    }
  }

  .HeroLp__logo {
    @media (${p => p.theme.xsMax}) {
      max-width: 440px;
      margin: 0 auto;
      margin-top: 32px;
    }

    @media (${p => p.theme.smMin}) {
      position: absolute;
      top: 110px;
      right: 53px;
      max-width: 240px;
    }

    @media (${p => p.theme.mdMin}) {
      top: 60px;
      right: 96px;
    }

    @media (${p => p.theme.lgMin}) {
      top: 31px;
      right: 120px;
      max-width: 250px;
    }

    .mobile {
      @media (${p => p.theme.smMin}) {
        display: none;
      }
    }

    .tab {
      @media (${p => p.theme.xsMax}) {
        display: none;
      }
    }
  }

  .HeroLp__cans {
    max-width: 400px;
    text-align: right;
    z-index: 1;

    @media (${p => p.theme.xsMax}) {
      margin-top: 45px;
    }

    @media (${p => p.theme.smMin}) {
      position: absolute;
      right: 20px;
      bottom: 18px;
      max-width: 40vw;
    }

    @media (${p => p.theme.mdMin}) {
      right: 30px;
      bottom: -50px;
      max-width: 540px;
    }

    @media (${p => p.theme.lgMin}) {
      right: 0;
      bottom: -80px;
      max-width: 660px;
    }
  }

  .HeroLp__scrollBtn {
    color: inherit;
    background: none;
    font: inherit;
    border: none;
    cursor: pointer;
  }
`;

HeroLpStyled.defaultProps = {
  theme: defaultTheme,
};

export default HeroLpStyled;
