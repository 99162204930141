import styled from 'styled-components';
import * as defaultTheme from '../variables';

import bgSocial from '../../images/bg_Social_tab.jpg';

const CompetitionOverviewStyled = styled('div')`
  color: ${p => p.theme.white};
  background-color: ${p => p.theme.black};
  background-image: url(${bgSocial});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  .CompetitionOverview__inner {
    position: relative;
    width: 100%;
    max-width: ${p => p.theme.maxWidth}px;
    margin: 0 auto;
    padding: 75px 20px;
    overflow: auto;

    @media (${p => p.theme.smMin}) {
      display: flex;
      justify-content: space-between;
    }

    @media (${p => p.theme.lgMin}) {
      padding: 75px 0;
    }
  }

  .CompetitionOverview__heading {
    @media (${p => p.theme.smMin}) {
      max-width: 420px;
      padding-right: 20px;
    }
  }

  .CompetitionOverview__btn {
    margin-top: 10px;
  }

  .CompetitionOverview__media {
    flex-shrink: 0;
    width: 100%;
    height: auto;

    @media (${p => p.theme.xsMax}) {
      margin-top: 30px;
    }

    @media (${p => p.theme.smMin}) {
      width: 447px;
      height: 251px;
    }

    @media (${p => p.theme.mdMin}) {
      width: 636px;
      height: 358px;
    }
  }
`;

CompetitionOverviewStyled.defaultProps = {
  theme: defaultTheme,
};

export default CompetitionOverviewStyled;
