import React from 'react';
import PropTypes from 'prop-types';

import CollectorCansStyled from './CollectorCans.style';
import imgCan1 from '../../images/can-us-1.png';
import imgCan2 from '../../images/can-us-2.png';
import imgCan3 from '../../images/can-us-3.png';
import imgCan4 from '../../images/can-us-4.png';
import imgCan5 from '../../images/can-us-5.png';
import imgCan6 from '../../images/can-us-6.png';
import imgCan7 from '../../images/can-us-7.png';
import imgCan8 from '../../images/can-us-8.png';

import Heading from '../Heading/Heading';

const cans = [
  { src: imgCan1, title: 'Original / Lord Shaxx' },
  { src: imgCan2, title: 'Original / Fanatic' },
  { src: imgCan3, title: 'Punched / Zavala' },
  { src: imgCan4, title: 'Punched / Machinist' },
  { src: imgCan5, title: 'Sugar Free / Ikora' },
  { src: imgCan6, title: 'Sugar Free / Hangman' },
  { src: imgCan7, title: 'Zero Carb / Cayde-6' },
  { src: imgCan8, title: 'Zero Carb / Uldren' },
];

const CollectorCans = ({ light, ...rest }) => {
  const images = cans.map((can, index) => (
    <div key={index.toString()} className="CollectorCans__can">
      <img className="CollectorCans__canImg" src={can.src} alt={can.title} />
      <div>
        {can.title}
        <div>
          {`${index + 1}`} of {cans.length}
        </div>
      </div>
    </div>
  ));

  return (
    <CollectorCansStyled id="CollectorCans" light={light} {...rest}>
      <div className="CollectorCans__inner">
        <div className="CollectorCans__top">
          <Heading className="CollectorCans__subtitle" size="sm" full>
            COLLECTOR'S EDITION CANS
          </Heading>
          <div className="CollectorCans__desc">
            Collect all 8 original designs featuring the iconic Vanguards, the
            Barons and Uldren Sov. Collect can codes to win prizes.
          </div>
        </div>

        <div className="CollectorCans__cans">{images}</div>
      </div>
    </CollectorCansStyled>
  );
};

CollectorCans.propTypes = {
  light: PropTypes.bool,
};

CollectorCans.defaultProps = {
  light: false,
};

export default CollectorCans;
