import styled from 'styled-components';
import * as defaultTheme from '../variables';
import { fadeInUp } from '../animations';

import bgCollectorCansLight from '../../images/bg_CollectorCans_light.jpg';

const UpgradeStyled = styled('div')`
  position: relative;
  background-color: #d8d8d8;
  background-image: url(${bgCollectorCansLight});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  animation-name: ${fadeInUp};
  animation-duration: 800ms;
  animation-delay: 70ms;
  animation-timing-function: cubic-bezier(0.7, 0, 0.3, 1);

  .Upgrade__inner {
    position: relative;
    width: 100%;
    max-width: ${p => p.theme.maxWidth}px;
    margin: 0 auto;
    padding: 75px 20px;

    @media (${p => p.theme.lgMin}) {
      display: flex;
      padding: 75px 0;
    }
  }

  .Upgrade__title {
    @media (${p => p.theme.mdMax}) {
      margin-bottom: 59px;
    }

    @media (${p => p.theme.smMin}) and (${p => p.theme.mdMax}) {
      display: flex;
      align-items: flex-start;
    }

    @media (${p => p.theme.lgMin}) {
      order: 2;
      padding-top: 72px;
      padding-left: 50px;
    }
  }

  .Upgrade__heading {
    @media (${p => p.theme.smMin}) and (${p => p.theme.mdMax}) {
      white-space: nowrap;
    }

    @media (${p => p.theme.lgMin}) {
      max-width: 430px;
    }
  }

  .Upgrade__subtitle {
    @media (${p => p.theme.smMin}) and (${p => p.theme.mdMax}) {
      max-width: 420px;
      margin-top: 12px;
      margin-left: 53px;
    }

    @media (${p => p.theme.lgMin}) {
      max-width: 420px;
    }
  }

  .Upgrade__blocks {
    position: relative;

    @media (${p => p.theme.smMin}) {
      display: flex;
      justify-content: space-around;
    }

    @media (${p => p.theme.lgMin}) {
      justify-content: flex-start;
    }
  }

  .Upgrade__block {
    display: flex;

    @media (${p => p.theme.smMin}) {
      flex-direction: column;
      align-items: center;
      width: 33%;
    }

    @media (${p => p.theme.lgMin}) {
      width: 230px;
    }

    &:not(:last-child) {
      @media (${p => p.theme.xsMax}) {
        padding-bottom: 26px;
      }
    }
  }

  .Upgrade__img {
    display: flex;
    flex: none;
    height: 116px;

    @media (${p => p.theme.smMin}) {
      height: 162px;
    }

    & > img {
      width: auto;
      height: 116px;

      @media (${p => p.theme.smMin}) {
        width: auto;
        height: 162px;
      }
    }
  }

  .Upgrade__desc {
    width: 100%;
    padding-left: 18px;
    font-size: 14px;
    line-height: 1.71;

    @media (${p => p.theme.smMin}) {
      padding: 14px 20px;
      text-align: center;
    }
  }

  .Upgrade__iconTitle {
    margin-top: 0;
    margin-bottom: 8px;
    font-size: 16px;
    font-weight: 900;
    line-height: 18px;
    text-transform: uppercase;

    @media (${p => p.theme.smMin}) {
      width: 140px;
      margin-right: auto;
      margin-left: auto;
      margin-bottom: 10px;
    }
  }

  .Upgrade__iconLink {
    display: block;
    color: #3d3f57;
    text-decoration: underline;
  }
`;

UpgradeStyled.defaultProps = {
  theme: defaultTheme,
};

export default UpgradeStyled;
