import React from 'react';

import CodeFormStyled from './CodeForm.style';
import searchArrow from '../../images/icon_searchArrow.svg';
import canTab from '../../images/can_tab.svg';

import Label from '../Label/Label';
import ModalController from '../ModalController/ModalController';
import ModalProgramOver from '../ModalProgramOver/ModalProgramOver';

const CodeForm = () => {
  return (
    <CodeFormStyled>
      <div className="CodeForm__inner">
        <ModalController style={{ width: '100%' }}>
          {(isOpen, handleToggle) => (
            <form
              className="CodeForm__form"
              onSubmit={e => {
                e.preventDefault();
                handleToggle();
              }}
            >
              <Label htmlFor="code">Enter Code from Tab</Label>
              <div className="CodeForm__field">
                <input
                  className="CodeForm__input"
                  id="code"
                  placeholder="XXXX-XXXX-XXXX"
                />
                <button className="CodeForm__btn" type="submit">
                  <img src={searchArrow} alt="" />
                </button>
              </div>

              {isOpen && <ModalProgramOver onClose={handleToggle} />}
            </form>
          )}
        </ModalController>

        <div className="CodeForm__canTab">
          <img src={canTab} alt="" />
        </div>
      </div>
    </CodeFormStyled>
  );
};

export default CodeForm;
