import React from 'react';

import HeroLpStyled from './HeroLp.style';
import canLineupUS from '../../images/can-lineup-US.png';

import Heading from '../Heading/Heading';
import CodeForm from '../CodeForm/CodeForm';

const HeroLp = () => {
  return (
    <HeroLpStyled>
      <div className="HeroLp__video">
        <video autoPlay loop muted playsInline>
          <source
            src="https://s3.amazonaws.com/oblivion-data/public/destiny/d2_weapons.mp4"
            type="video/mp4"
          />
        </video>
      </div>
      <div className="HeroLp__inner">
        <div className="HeroLp__heading">
          <Heading size="xl">
            <div className="HeroLp__title">ENTER.PLAY.WIN.</div>
            <small>WINNERS EVERY DAY</small>
          </Heading>
          <p className="HeroLp__desc">
            Enter can codes to win prizes, unlock an exclusive in-game emblem
            and earn special access within the Rockstar Riots In-Game
            Competition.
          </p>
          <CodeForm className="HeroLp__code" />
        </div>

        <div className="HeroLp__cans">
          <img src={canLineupUS} alt="" />
        </div>
      </div>
    </HeroLpStyled>
  );
};

export default HeroLp;
