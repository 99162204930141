import styled from 'styled-components';
import * as defaultTheme from '../variables';
import { fadeInUp } from '../animations';

import bgPrizes from '../../images/bg_Prizes.jpg';

const PrizesStyled = styled('div')`
  min-height: 460px;
  padding-bottom: 48px;
  color: ${p => p.theme.white};
  background-color: ${p => p.theme.black};
  background-image: url(${bgPrizes});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  animation-name: ${fadeInUp};
  animation-duration: 800ms;
  animation-delay: 140ms;
  animation-timing-function: cubic-bezier(0.7, 0, 0.3, 1);

  .Prizes__inner {
    position: relative;
    width: 100%;
    max-width: ${p => p.theme.maxWidth}px;
    padding: 75px 20px 0;
    margin: 0 auto;

    @media (${p => p.theme.lgMin}) {
      padding: 75px 0 0;
    }
  }

  .Prizes__top {
    @media (${p => p.theme.mdMin}) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: 92%;
    }

    @media (${p => p.theme.lgMin}) {
      max-width: 100%;
    }
  }

  .Prizes__enlist {
    @media (${p => p.theme.smMax}) {
      max-width: 400px;
      margin: 0 auto;
    }

    @media (${p => p.theme.mdMin}) {
      width: 40%;
      margin-right: 30px;
    }
  }

  .Prizes__btn {
    margin-top: 10px;
    padding: 0 32px;
  }

  .Prizes__prize {
    font-size: 16px;
    font-weight: 900;
    line-height: 1.88;
    letter-spacing: 1px;
    text-align: center;

    @media (${p => p.theme.smMax}) {
      margin-top: 50px;
    }

    @media (${p => p.theme.mdMin}) {
      width: 30%;
      padding: 0 16px;
    }

    img {
      max-height: 255px;
      margin-bottom: 16px;

      @media (${p => p.theme.mdMin}) {
        height: 17vw;
      }

      @media (${p => p.theme.lgMin}) {
        height: 255px;
      }
    }

    p {
      margin: 0;
      line-height: 1;
    }
  }

  .Prizes__grand {
    margin-bottom: 59px;

    @media (${p => p.theme.smMin}) {
      display: flex;
      align-items: flex-start;
    }
  }

  .Prizes__subtitle {
    min-width: 300px;

    @media (${p => p.theme.xsMax}) {
      margin-bottom: 18px;
    }

    @media (${p => p.theme.smMin}) {
      margin-right: 32px;
    }
  }

  .Prizes__desc {
    width: 100%;

    @media (${p => p.theme.smMin}) {
      max-width: 500px;
    }
  }

  .Prizes__linkBtn {
    padding: 0;
    color: inherit;
    background: none;
    font: inherit;
    text-decoration: underline;
    border: none;
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }
  }
`;

PrizesStyled.defaultProps = {
  theme: defaultTheme,
};

export default PrizesStyled;
