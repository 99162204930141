import styled from 'styled-components';
import PropTypes from 'prop-types';
import * as defaultTheme from '../variables';

const CodeFormStyled = styled('div')`
  .CodeForm__inner {
    display: flex;
    align-items: flex-end;
    max-width: 400px;
  }

  .CodeForm__form {
    width: 100%;
    padding-top: 24px;
  }

  .CodeForm__field {
    display: flex;
  }

  .CodeForm__input {
    width: calc(100% - 52px);
    height: 50px;
    padding: 8px 16px;
    color: #000;
    font-size: 16px;
    letter-spacing: 4px;
    border: 0;
    border-radius: 0;
  }

  .CodeForm__select {
    display: block;
    min-width: 190px;
    padding: 4px 18px 4px 6px;
    background-color: white;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23000' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 6px center;
    background-size: 8px 10px;
    font-size: 13px;
    border: 1px solid white;
    border-radius: 0;
    appearance: none;
  }

  .CodeForm__btn {
    flex-shrink: 0;
    width: 52px;
    height: 50px;
    color: #fff;
    background-color: #000;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 2px;
    border: 2px solid #fff;
    cursor: pointer;
  }

  .CodeForm__canTab {
    flex-shrink: 0;
    width: 51px;
    margin-left: 16px;
  }

  .CodeForm__info {
    max-width: 400px;
    margin: 12px 0;
    padding: 8px 12px;
    color: #ff0725;
    background-color: rgba(0, 0, 0, 0.5);
    text-shadow: 0px 0px 7px #000;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.5;
  }
`;

CodeFormStyled.propTypes = {
  theme: PropTypes.shape({
    white: PropTypes.string.isRequired,
    black: PropTypes.string.isRequired,
  }),
};

CodeFormStyled.defaultProps = {
  theme: defaultTheme,
};

export default CodeFormStyled;
