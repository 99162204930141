import React from 'react';

import Layout from '../components/Layout/Layout';
import Header from '../components/Header/Header';
import HeroLp from '../components/HeroLp/HeroLp';
import Upgrade from '../components/Upgrade/Upgrade';
import Prizes from '../components/Prizes/Prizes';
import CompetitionOverview from '../components/CompetitionOverview/CompetitionOverview';
import CollectorCans from '../components/CollectorCans/CollectorCans';
import Social from '../components/Social/Social';
import Footer from '../components/Footer/Footer';

import SEO from '../components/seo';

const IndexPage = props => (
  <Layout>
    <SEO title="Home" />
    <Header pathname={props.location.pathname} />
    <HeroLp />
    <Upgrade />
    <Prizes />
    <CompetitionOverview video />
    <CollectorCans light />
    <Social />
    <Footer />
  </Layout>
);

export default IndexPage;
