import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Slider from 'react-slick';

import arrowUrl from './images/icon_sliderArrow.svg';
import dotUrl from './images/icon_sliderDot.svg';
import dotBlackUrl from './images/icon_sliderDot_black.svg';
import dotActiveUrl from './images/icon_sliderDotActive.svg';
import dotActiveBlackUrl from './images/icon_sliderDotActive_black.svg';

const defaultSettings = {
  speed: 500,
  centerPadding: 0,
  slidesToShow: 1,
  arrows: true,
  variableWidth: true,
  centerMode: false,
  infinite: true,
  dots: false,
  initialSlide: 0,
};

const CarouselComponent = ({ settings, slides, ...props }) => {
  const newSettings = { ...defaultSettings, ...settings };
  const items = slides.map(slide => (
    <div key={slide.src}>
      {slide.link ? (
        <a {...slide.link}>
          <img src={slide.src} alt={slide.alt} />
        </a>
      ) : (
        <img src={slide.src} alt={slide.alt} />
      )}
      {slide.overlay && <div className="Slider__overlay">{slide.overlay}</div>}
      {slide.text && <div className="Slider__desc">{slide.text}</div>}
    </div>
  ));

  return (
    <Slider {...newSettings} {...props}>
      {items}
    </Slider>
  );
};

const Carousel = styled(CarouselComponent)`
  position: relative;
  display: block;
  overflow-x: hidden;
  user-select: none;
  touch-action: pan-y;

  .slick-list {
    position: relative;
    display: block;
    margin: 0;
    padding: 0;

    &:focus {
      outline: none;
    }

    &.dragging {
      cursor: pointer;
      cursor: hand;
    }
  }

  .slick-track,
  .slick-list {
    transform: translate3d(0, 0, 0);
  }

  .slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: block;

    &:before,
    &:after {
      display: table;
      content: '';
    }

    &:after {
      clear: both;
    }
  }

  .slick-loading .slick-track {
    visibility: hidden;
  }

  .slick-slide {
    position: relative;
    display: none;
    float: left;
    width: ${p => (p.customWidth ? p.customWidth[0] : 375)}px;
    height: 100%;
    min-height: 1px;
    padding: 0 30px;

    @media (min-width: 768px) {
      width: ${p => (p.customWidth ? p.customWidth[1] : 447)}px;
    }

    @media (min-width: 1440px) {
      width: ${p => (p.customWidth ? p.customWidth[2] : 690)}px;
      padding: 0 60px;
    }

    img {
      display: inline-block;
    }

    &.slick-loading img {
      display: none;
    }

    &.dragging img {
      pointer-events: none;
    }
  }

  &[dir='rtl'] .slick-slide {
    float: right;
  }

  &.slick-initialized .slick-slide {
    display: block;
  }

  &.slick-loading .slick-slide {
    visibility: hidden;
  }

  &.slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }

  .slick-arrow.slick-hidden {
    display: none;
  }

  .slick-prev,
  .slick-next {
    position: absolute;
    top: 0;
    display: block;
    width: 50px;
    height: calc(100% - 74px);
    padding: 0;
    color: transparent;
    background-color: transparent;
    font-size: 0;
    line-height: 0;
    cursor: pointer;
    border: none;
    outline: none;
    z-index: 1;

    &:hover,
    &:focus {
      outline: none;

      &::before {
        opacity: 0.8;
      }
    }

    &.slick-disabled::before {
      opacity: 0.25;
    }

    &::before {
      position: absolute;
      content: '';
      left: 0;
      width: 50px;
      height: 50px;
      margin-top: -25px;
      background-image: url(${arrowUrl});
      background-repeat: no-repeat;
      background-position: center;
      background-size: 50px 50px;
      transition: opacity 150ms;
    }
  }

  .slick-prev {
    left: 0;
  }

  .slick-next {
    right: 0;

    &::before {
      transform: scaleX(-1);
    }
  }

  .slick-dots {
    display: block;
    width: 100%;
    padding: 20px 0;
    margin: 0;
    list-style: none;
    text-align: center;

    li {
      position: relative;
      display: inline-block;
      margin: 0;
      padding: 0;
      cursor: pointer;

      button {
        display: block;
        width: 34px;
        height: 34px;
        font-size: 0;
        line-height: 0;
        cursor: pointer;
        border: 0;
        outline: none;
        background-color: transparent;
        background-image: ${p =>
          p.light ? `url(${dotBlackUrl})` : `url(${dotUrl})`};
        background-repeat: no-repeat;
        background-position: center;
        background-size: 16px 12px;

        @media (min-width: 768px) {
          background-size: 18px 14px;
        }

        &:hover {
          outline: none;
        }
      }

      &.slick-active {
        button {
          background-image: ${p =>
            p.light ? `url(${dotActiveBlackUrl})` : `url(${dotActiveUrl})`};
          background-size: 30px 24px;

          @media (min-width: 768px) {
            background-size: 34px 26px;
          }
        }
      }
    }
  }

  .Slider__desc {
    position: relative;
    margin: 0;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    font-size: 16px;

    @media (min-width: 1024px) {
      letter-spacing: 2px;
    }
  }

  .Slider__overlay {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    right: 30px;
    bottom: 0;
    left: 30px;
    padding: 0 30px;
    background-color: rgba(0, 0, 0, 0.7);
    line-height: 1.4;
    opacity: 1;
    transition: opacity 0.25s;

    @media (min-width: 1440px) {
      right: 60px;
      left: 60px;
    }

    &:hover {
      opacity: 1;
    }

    h4 {
      margin: 2px 0;
      font-size: 16px;
      text-transform: uppercase;

      @media (min-width: 1440px) {
        font-size: 20px;
      }
    }
  }
`;

Carousel.propTypes = {
  settings: PropTypes.object,
  slides: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string.isRequired,
      alt: PropTypes.string,
      text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    }).isRequired,
  ),
  customWidth: PropTypes.arrayOf(PropTypes.number),
  light: PropTypes.bool,
};

Carousel.defaultProps = {
  settings: defaultSettings,
  customWidth: null,
  light: false,
};

export default Carousel;
