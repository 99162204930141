import styled from 'styled-components';
import * as defaultTheme from '../variables';

import bgLightMobile from '../../images/bg_CollectorCans_light_mobile.jpg';
import bgDarkMobile from '../../images/bg_CollectorCans_dark_mobile.jpg';
import bgLight from '../../images/bg_CollectorCans_light.jpg';
import bgDark from '../../images/bg_CollectorCans_dark.jpg';

const CollectorCansStyled = styled('div')`
  color: ${p => (p.light ? p.theme.black : p.theme.white)};
  background-image: ${p =>
    p.light ? `url(${bgLightMobile})` : `url(${bgDarkMobile})`};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  @media (${p => p.theme.smMin}) {
    background-image: ${p => (p.light ? `url(${bgLight})` : `url(${bgDark})`)};
  }

  .CollectorCans__inner {
    position: relative;
    width: 100%;
    max-width: ${p => p.theme.maxWidth}px;
    margin: 0 auto;
    padding: 75px 20px 35px;
    overflow: auto;

    @media (${p => p.theme.lgMin}) {
      padding: 75px 0 35px;
    }
  }

  .CollectorCans__top {
    padding-bottom: 48px;

    @media (${p => p.theme.smMin}) {
      display: flex;
      align-items: flex-start;
      padding-bottom: 60px;
    }
  }

  .CollectorCans__subtitle {
    min-width: 300px;

    @media (${p => p.theme.xsMax}) {
      margin-bottom: 18px;
    }

    @media (${p => p.theme.smMin}) {
      margin-right: 32px;
    }
  }

  .CollectorCans__desc {
    width: 100%;

    @media (${p => p.theme.smMin}) {
      max-width: 386px;
    }
  }

  .CollectorCans__cans {
    display: flex;
    flex-wrap: wrap;
  }

  .CollectorCans__can {
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 1.4;
    width: 50%;
    padding: 0 30px;
    margin-bottom: 40px;

    @media (${p => p.theme.smMin}) {
      width: 25%;
    }

    @media (${p => p.theme.lgMin}) {
      font-size: 16px;
      letter-spacing: 2px;
    }
  }

  .CollectorCans__canImg {
    @media (${p => p.theme.mdMin}) {
      max-width: 205px;
    }
  }
`;

CollectorCansStyled.defaultProps = {
  theme: defaultTheme,
  light: false,
};

export default CollectorCansStyled;
