import React, { Fragment } from 'react';

import PrizesStyled from './Prizes.style';
import imgPrizes1 from '../../images/prizes-xx-1.png';
import imgPrizes2 from '../../images/prizes-xx-2.png';
import imgSling1 from '../../images/sling1.jpg';
import imgSling2 from '../../images/sling2.jpg';
import imgSling3 from '../../images/sling3.jpg';
import imgSling4 from '../../images/sling4.jpg';
import imgSling5 from '../../images/sling5.jpg';
import imgSling6 from '../../images/sling6.jpg';
import imgSling7 from '../../images/sling7.jpg';
import imgSling8 from '../../images/sling8.jpg';

import Heading from '../Heading/Heading';
import Button from '../Button/Button';
import Carousel from '../Carousel/Carousel';
import ModalController from '../ModalController/ModalController';
import ModalProgramOver from '../ModalProgramOver/ModalProgramOver';

const Prizes = () => {
  return (
    <PrizesStyled>
      <div className="Prizes__inner">
        <div className="Prizes__top">
          <div className="Prizes__enlist">
            <Heading size="xl">
              CHANCE
              <br />
              TO WIN
            </Heading>
            <p>
              Every can code earns 1 daily, 1 weekly and 1 grand prize entry.
              More codes = more chances to win.
            </p>
            <ModalController>
              {(isOpen, handleToggle) => (
                <Fragment>
                  <Button
                    className="Prizes__btn"
                    variant="info"
                    onClick={handleToggle}
                  >
                    ENTER CAN CODE
                  </Button>

                  {isOpen && <ModalProgramOver onClose={handleToggle} />}
                </Fragment>
              )}
            </ModalController>
          </div>
          <div className="Prizes__prize">
            <img src={imgPrizes1} alt="" />
            <p>3 DAILY WINNERS</p>
          </div>
          <div className="Prizes__prize">
            <img src={imgPrizes2} alt="" />
            <p>3 WEEKLY WINNERS</p>
          </div>
        </div>
      </div>

      <div>
        <div className="Prizes__inner Prizes__grand">
          <Heading className="Prizes__subtitle" size="sm" full>
            GRAND PRIZE
          </Heading>
          <div className="Prizes__desc">
            <span>
              Win… The Ultimate Joyride! Rockstar Energy has created a
              completely custom Destiny 2: Forsaken-inspired Slingshot Roadster.
              The Slingshot® is an open-air, 3-wheeled roadster that you can
              drive on public roads.
            </span>
            <a
              href="https://slingshot.polaris.com/en-us/"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'underline' }}
            >
              Learn more here.
            </a>
          </div>
        </div>

        <Carousel
          slides={[
            {
              src: imgSling1,
              alt: '',
            },
            {
              src: imgSling2,
              alt: '',
            },
            {
              src: imgSling2,
              alt: '',
              overlay: (
                <div>
                  <h4>Street Legal Status</h4>
                  <div>
                    Slingshot® is street legal and equipped for use on all
                    public roads.
                  </div>
                </div>
              ),
            },
            {
              src: imgSling3,
              alt: '',
            },
            {
              src: imgSling4,
              alt: '',
            },
            {
              src: imgSling4,
              alt: '',
              overlay: (
                <div>
                  <h4>License Requirements</h4>
                  <div>
                    Over 40 states in the US require just a driver
                    {"'"}s license to operate a Slingshot®.
                  </div>
                </div>
              ),
            },
            {
              src: imgSling5,
              alt: '',
            },
            {
              src: imgSling6,
              alt: '',
            },
            {
              src: imgSling6,
              alt: '',
              overlay: (
                <div>
                  <h4>Manual transmission</h4>
                  <div>
                    All Slingshot® models have 5-speed manual transmission to
                    add to your precision control and the thrill of the ride.
                  </div>
                </div>
              ),
            },
            {
              src: imgSling7,
              alt: '',
            },
            {
              src: imgSling8,
              alt: '',
            },
            {
              src: imgSling8,
              alt: '',
              overlay: (
                <div>
                  <h4>Performance-Inspired Engine</h4>
                  <div>
                    All Slingshot® models are equipped with a GM Ecotec LE9 2.4L
                    DOHC motor to give you the ride of a lifetime.
                  </div>
                </div>
              ),
            },
          ]}
        />
      </div>
    </PrizesStyled>
  );
};

export default Prizes;
