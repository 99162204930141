import PropTypes from 'prop-types';
import styled from 'styled-components';

const Label = styled('label')`
  display: inline-block;
  max-width: 100%;
  padding-bottom: 0.5em;
  color: inherit;
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;
`;

Label.propTypes = {
  htmlFor: PropTypes.string,
};

Label.defaultProps = {
  htmlFor: null,
};

export default Label;
