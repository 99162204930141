import React from 'react';

import UpgradeStyled from './Upgrade.style';
import upgrade1 from '../../images/upgrade-xx-1.png';
import upgrade2 from '../../images/upgrade-xx-2.png';
import emblem from '../../images/emblem.png';

import Heading from '../Heading/Heading';

const Upgrade = (...rest) => {
  return (
    <UpgradeStyled {...rest}>
      <div className="Upgrade__inner">
        <div className="Upgrade__title">
          <Heading className="Upgrade__heading" size="lg">
            Score with cans
          </Heading>
          <p className="Upgrade__subtitle">
            Look for the Destiny 2: Forsaken collector's edition cans and
            collect tab codes for multiple ways to score.
          </p>
        </div>

        <div className="Upgrade__blocks">
          <div className="Upgrade__block">
            <div className="Upgrade__img">
              <img src={upgrade1} alt="" />
            </div>
            <div className="Upgrade__desc">
              <h4 className="Upgrade__iconTitle">Chance To Win Prizes</h4>
              <span>
                Earn entries to win Destiny 2: Forsaken game, PS4 Pro and
                Slingshot Roadster.
              </span>
            </div>
          </div>
          <div className="Upgrade__block">
            <div className="Upgrade__img">
              <img src={upgrade2} alt="" />
            </div>
            <div className="Upgrade__desc">
              <h4 className="Upgrade__iconTitle">In-Game Competition</h4>
              <span>
                Weekly challenges to win more prizing and a fly-away trip to
                compete at Bungie.
              </span>
            </div>
          </div>
          <div className="Upgrade__block">
            <div className="Upgrade__img">
              <img src={emblem} alt="" />
            </div>
            <div className="Upgrade__desc">
              <h4 className="Upgrade__iconTitle">Exclusive Emblem</h4>
              <span>
                Unlock an exclusive in-game emblem inspired by Rockstar Energy
                Drink.
              </span>
            </div>
          </div>
        </div>
      </div>
    </UpgradeStyled>
  );
};

export default Upgrade;
