import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import CompetitionOverviewStyled from './CompetitionOverview.style';
import imgScreenshot from '../../images/video-screenshot.jpg';

import Heading from '../Heading/Heading';
import Button from '../Button/Button';

const CompetitionOverview = ({ video, ...rest }) => {
  return (
    <CompetitionOverviewStyled {...rest}>
      <div className="CompetitionOverview__inner">
        <div
          className="CompetitionOverview__heading"
          style={{ maxWidth: '450px' }}
        >
          <Heading>ROCKSTAR RIOTS IN‑GAME COMPETITION</Heading>
          <p>
            Rockstar Energy powers the Destiny 2: Forsaken competition series.
            Play for weekly prizes, earn ranking by game mode, and a chance to
            win a fly-away experience to the live finals event at Bungie Studios
            in Washington, USA.
          </p>
          <Button className="CompetitionOverview__btn" variant="info">
            <Link to="/competition">LEARN MORE</Link>
          </Button>
        </div>

        {video ? (
          <video
            className="CompetitionOverview__media"
            controls
            poster={imgScreenshot}
          >
            <source
              src="https://s3.amazonaws.com/oblivion-data/public/destiny/competition_US.mp4"
              type="video/mp4"
            />
          </video>
        ) : (
          <img
            className="CompetitionOverview__media"
            src={imgScreenshot}
            alt=""
          />
        )}
      </div>
    </CompetitionOverviewStyled>
  );
};

CompetitionOverview.propTypes = {
  video: PropTypes.bool,
};

CompetitionOverview.defaultProps = {
  video: false,
};

export default CompetitionOverview;
